@import "~@fortawesome/fontawesome-free/css/all.css";
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  font-family: "Roboto Serif", serif !important;
  background-color: #020617;
  margin: 0;
  color: #ffffff;
  cursor: url("assets/homePage/cursor.svg"), auto;
}

.roboto {
  font-family: "Roboto Serif", serif !important;
}

.cairo {
  font-family: "Cairo", sans-serif;
}

.mute {
  color: #b7b7b7 !important;
}

.center {
  margin-right: auto;
  margin-left: auto;
  display: block;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #49aeac;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #49aeac;
}

.main_color {
  color: #49aeac;
}

.fade-in-up {
  opacity: 0;
  transform: translateY(30px);
  animation: fadeInUp 1s ease-out;
  animation-fill-mode: forwards;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.overflow-hidden {
  overflow: hidden;
}

.text-xxs {
  font-size: 10px;
}